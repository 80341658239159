.about__header {
  background: linear-gradient(
    90deg,
    rgba(71, 122, 132, 0.09877454399728647) 0%,
    rgba(71, 122, 132, 0.65) 14%,
    rgba(45, 95, 115, 1) 50%,
    rgba(71, 122, 132, 0.65) 86%,
    rgba(71, 122, 132, 0.1) 100%
  );
   width: var(--container-width-lg);
   margin: auto;
}
.about__container {
  display: flex;
  flex-direction: row;
  gap: 0.75rem; 
  background: linear-gradient(
    90deg,
    rgba(71, 122, 132, 0.09877454399728647) 0%,
    rgba(71, 122, 132, 0.65) 14%,
    rgba(71, 122, 132, 1) 50%,
    rgba(71, 122, 132, 0.65) 86%,
    rgba(71, 122, 132, 0.1) 100%
  );
  padding: 24px;
  border-radius: 2rem;
  width: var(--container-width-lg);
  transition: var(--transition);
}


.about__container:hover {
  background-color: rgba(255, 255, 255, 0.164);
  backdrop-filter: blur(5px);
  border-color: var(--color-white-500) !important;
  border: 2px solid;
}

.img__container {
  position: relative;
}

.about__me-image {
  border-radius: 2rem;
  overflow: hidden;
  width: 50vh;
  margin-left: 2.5rem;
}

.about__me-image:hover {
  transform: rotate(0);
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.about__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  /* transition: var(--transition); */
}

.about__card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  border-color: var(--color-primary-variant);
  cursor: default;
}

.about__content p {
  width: 80%;
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}
.about__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}


/* MEDIA QUERIES (EXTRA LARGE DEVICES) */

@media screen and (min-width: 1280px) {
 
}

/* MEDIA QUERIES (LARGE DEVICES) */

@media screen and (min-width: 1024px) {
  
}

 /* MEDIA QUERIES (MEDIUM DEVICES)  */

@media screen and (min-width: 768px) {
 
}

/* MEDIA QUERIES (SMALL DEVICES) */

@media screen and (max-width: 640px) {

  .container.about__container {
    flex-direction: column;
    width: var(--container-width-sm);
    gap: none;
    padding: 2rem 1rem 2rem;
  }
  .about__me-image {
    margin: auto;
    width: 75%;
  }
  .header__socials,
  .scroll__down {
    display: none;
  }
  .cta > a {
    width: 30%;
  }
  .about__header > h5 {
   display: none;
  }
}

/*MEDIA QUERY (MEDIUM DEVICES) */

@media screen and (min-width: 1024px) {
  /* .about__container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about__me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about__content p {
    margin: 1rem 0 1.5rem;
  } */
}

/* MEDIA QUERIES (SMALL DEVICES) */

@media screen and (min-width: 600px) {
  /* .about__container {
    width: 80%;
  }
  .about__me {
    width: 65%;
    margin: 0 auto 3rem;
  }
  .about_cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .about__content {
    text-align: center;
  }
  .about__content p {
    margin: 1.5rem 0;
  }
  .about__me-image {
    margin-top: 5vh;
    margin-right: 24%;
    width: 30vh;
  }
  .container.about__container {
    width: 80%;
  } */
}
