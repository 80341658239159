#portfolio{
  width: 100%;
  
}

.portfolio__header {
  background: linear-gradient(
    90deg,
    rgba(71, 122, 132, 0.09877454399728647) 0%,
    rgba(71, 122, 132, 0.65) 14%,
    rgba(45, 95, 115, 1) 50%,
    rgba(71, 122, 132, 0.65) 86%,
    rgba(71, 122, 132, 0.1) 100%
  );
  width: var(--container-width-lg);
  margin: auto;
}

.portfolio__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2rem;
  width: var(--container-width-lg);
  transition: var(--transition);
  margin: auto;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  transition: var(--transition);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio__item:hover {
  border-color: var(--color-white-500) !important;
  background-color: rgba(255, 255, 255, 0.164);
  backdrop-filter: blur(5px);
  border: 2px solid;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
  width: 50vh;
  height: 30vh;
  object-fit: cover;
}

.portfolio_img {
  height: 100%;
  width: 100%;
}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
  text-align: center;
}

.portfolio__item-cta {
  display: inline-flex;
  flex-direction: row!important;
  gap: 1rem;
  margin-bottom: 1rem;
  min-width: 50%;
}

.nohover:hover {
 background-color: var(--color-bg-variant);
 border: none;
}

/* MEDIA QUERY (LARGE DEVICES) */
@media screen and (max-width: 1536px) {
  .portfolio__container {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.2rem;
    width: 100%;
  }
}

@media screen and (max-width: 1280px) {
  .portfolio__container {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.2rem;
    width: 100%;
  }
}

/*MEDIA QUERY (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: repeat(1, 1fr);
    gap: 1.2rem;
     width: 85%;
  }
}
@media screen and (max-width: 900px) {
  .portfolio__container {
    width: 85%;
    grid-template-columns:  repeat(1, 1fr);
    gap: 1.2rem;
  }
}
@media screen and (max-width: 768px) {
  .portfolio__container {
    grid-template-columns:  repeat(1, 1fr);
    gap: 1.2rem;
  }
}


/* MEDIA QUERIES (SMALL DEVICES) */
@media screen and (max-width: 600px) {
  .portfolio__container {
    width: 95%;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;
    margin: auto;
  }
  .portfolio__item:not(:last-child) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  h5 {
    text-align: center;
  }
  .portfolio__item-image {
    max-width: 40vh;
    margin: auto;
  }
  .portfolio__item-cta {
    align-self: center;
    flex-direction: row;
  }
  .portfolio__header > h5 {
    display: none;
  }
}
