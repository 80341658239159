.experience__header {
   background: linear-gradient(
    90deg,
    rgba(71, 122, 132, 0.09877454399728647) 0%,
    rgba(71, 122, 132, 0.65) 14%,
    rgba(45, 95, 115, 1) 50%,
    rgba(71, 122, 132, 0.65) 86%,
    rgba(71, 122, 132, 0.1) 100%
  );
   width: var(--container-width-lg);
   margin: auto;
}

.experience__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.experience__container > div {
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience__container > div:hover {
  background-color: rgba(255, 255, 255, 0.164);
  backdrop-filter: blur(5px);
  border-color: var(--color-white-500) !important;
  border: 2px solid;
  cursor: default;
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experience__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
}

.experience__details {
  display: flex;
  gap: 1rem;
}

.experience__details-icons {
  margin-top: 6px;
  color: var(--color-primary);
}
/*MEDIA QUERY (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }
  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .experience__content {
    padding: 1rem;
  }
}

/* MEDIA QUERIES (SMALL DEVICES) */

@media screen and (max-width: 600px) {
  .experience__container {
    gap: 1rem;
  }
  .experience__container > div {
    width: 90%;
    padding: 2rem 1rem;
  }
  .experience__header > h5 {
    display: none;
  }
}
