header {
  height: 40vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 32vh;
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(71, 122, 132, 0.09877454399728647) 0%,
    rgba(71, 122, 132, 0.43) 14%,
    rgba(71, 122, 132, 1) 50%,
    rgba(71, 122, 132, 0.43) 86%,
    rgba(71, 122, 132, 0.1) 100%
  );
  padding: 24px;
  border-radius: 2rem;
  transition: var(--transition)
}

.header__container:hover {
  border-color: var(--color-white-500) !important;
  background-color: rgba(255, 255, 255, 0.164);
  backdrop-filter: blur(5px);
  border: 2px solid;
}

span {
  font-size: 30px;
}

/* CTA */

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}
/* HEADER SOCIALS  */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  margin-left: 2vh;
  margin-bottom: 30vh;
  bottom: 3rem;
  position: fixed;
  filter: grayscale(1);
  filter: brightness(1.5);
  z-index: 3;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-white);
}

/* IMAGE */

.me {
  background-color: rgba(255, 255, 255, 0.164);
  backdrop-filter: blur(5px);
  /* border-color: var(--color-primary);
  border: 2px solid; */
  width: 22rem;
  height: 22rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 1rem;
  overflow: hidden;
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  backdrop-filter: blur(15px);
}

.about_me_img {
  border-radius: 0.75rem;
}

/* SCROLL DOWN */

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 200;
  font-size: 0.9rem;
  margin-right: 2vh;
  position: fixed;
  margin-bottom: 35vh;
  filter: grayscale(1);
  filter: brightness(1.5);
  z-index: 3;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

/* MEDIA QUERIES (SMALL DEVICES) */

@media screen and (max-width: 600px) {
  header {
    height: 35vh;
    padding: 2rem 1rem 2rem;
  }

  .container.header__container {
    width: 80%;
  }
  .header__socials,
  .scroll__down {
    display: none;
  }

  .cta > a {
    width: 30%;
  }
}
