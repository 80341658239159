.contact__header {
  background: linear-gradient(
    90deg,
    rgba(71, 122, 132, 0.09877454399728647) 0%,
    rgba(71, 122, 132, 0.65) 14%,
    rgba(45, 95, 115, 1) 50%,
    rgba(71, 122, 132, 0.65) 86%,
    rgba(71, 122, 132, 0.1) 100%
  );
  width: var(--container-width-lg);
  margin: auto;
}

.container.contact__container {
  width: 58%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact__option {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  transition: var(--transition);
}

.contact__option:hover {
  background-color: rgba(255, 255, 255, 0.164);
  backdrop-filter: blur(5px);
  border-color: var(--color-white-500) !important;
  border: 2px solid;
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}
/* FORM */

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  background-color:#477a84;
  padding: 4rem;
  border-radius: 1rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: #4d838e;
  border: 2px solid var(--color-primary);
  resize: none;
  color: var(--color-white);
  align-self: center;
}

::placeholder {
  color: 2px solid var(--color-primary);
   font-family: "Poppins", sans-serif;
}

.form-btn{
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  background: var(--color-primary);
  color: var(--color-bg);
  transition: var(--transition);
  margin: auto;
}

.form-btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}


/*MEDIA QUERY (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/* MEDIA QUERIES (SMALL DEVICES) */
@media screen and (max-width: 600px) {
  .container.contact__container {
    width: 90%;
    /* changed the width to fit a mobile phone size */
  }
  form {
    width: 80%;
    padding: 2.5rem;
  }
  input,
  textarea {
    width: 80%;
    align-items: center;
  }
  button {
    align-self: center;
  }
  .contact__header > h5 {
    display: none;
  }
}
